import store from '@/store';
import { axiosInstance } from '@/services/Service';

export class Backend {
  static http = axiosInstance;

  static userId () {
    return store.getters.userId;
  }

  static selectedUserId () {
    return this.http.selectedUserId();
  }

  static logout () {
    return this.http.post('/auth/logout').then(response => {
      store.commit('setMe', undefined);
      store.commit('saveMe');
      store.commit('setSelectedCollaborator', null);
      return response;
    });
  }

  static login (authCode) {
    return this.http.post('/auth/login', {
      authCode,
    });
  }

  static me () {
    return this.http.get('/auth/me')
      .then(response => {
        store.commit('setMe', response.data);
        store.commit('saveMe');
        return response;
      })
      .catch(err => {
        store.commit('setMe', null);
        store.commit('saveMe');
        store.commit('setSelectedCollaborator', null);
        throw err;
      });
  }

  static getApiTokens () {
    return this.http.get('/auth/api-token');
  }

  static generateApiToken (name) {
    return this.http.post('/auth/api-token', {
      name,
    });
  }

  static deleteApiToken (id) {
    return this.http.delete(`/auth/api-token/${id}`);
  }

  static getUserByKickUsername (kickUsername) {
    return this.http.get(`/user/by-kick-username/${kickUsername}`);
  }

  static getProfileSettings (userId) {
    return this.http.get(`/${userId ?? this.userId()}/profile/settings`);
  }

  static putProfileSettings (settings) {
    return this.http.put(`/${this.userId()}/profile/settings`, settings);
  }

  static searchUsers (search) {
    return this.http.get(`/search/${this.userId()}/collaborator`, {
      params: {
        search,
      },
    });
  }

  static collaboratorPermissions () {
    return this.http.get('/collaborator/permissions');
  }

  static collaboratorList () {
    return this.http.get(`/${this.userId()}/collaborator`);
  }

  static putCollaborator (collaboratorId, permissions) {
    return this.http.put(`/${this.userId()}/collaborator/${collaboratorId}`, {
      permissions,
    });
  }

  static deleteCollaborator (collaboratorId) {
    return this.http.delete(`/${this.userId()}/collaborator/${collaboratorId}`);
  }

  static getManagedUsers () {
    return this.http.get(`/${this.userId()}/collaborator/managed-user`);
  }

  static getCollaboratorSetting () {
    return this.http.get(`/${this.userId()}/collaborator/settings`);
  }

  static putCollaboratorSetting (setting) {
    return this.http.put(`/${this.userId()}/collaborator/settings`, {
      active: setting,
    });
  }

  static deleteManagedUserCollaborator (managedUserId) {
    return this.http.delete(`/${this.userId()}/collaborator/managed-user/${managedUserId}`);
  }

  static getVerifyCode () {
    return this.http.get('/auth/verify/code');
  }

  static unverify () {
    return this.http.post('/auth/unverify')
      .then(response => {
        this.me();
        return response;
      });
  }

  static refreshCookie () {
    return this.http.get('/auth/refresh-cookie')
      .then(response => {
        this.me();
        return response;
      });
  }

  static getBotCommandGroups () {
    return this.http.get(`/${this.selectedUserId()}/command/group`);
  }

  static getBotCommandGroupDetailed (id) {
    return this.http.get(`/${this.selectedUserId()}/command/group/${id}`);
  }

  static postBotCommandGroup (group) {
    return this.http.post(`/${this.selectedUserId()}/command/group`, group);
  }

  static putBotCommandGroup (id, group) {
    return this.http.put(`/${this.selectedUserId()}/command/group/${id}`, group);
  }

  static commandGroupToggleActive (group) {
    return this.http.patch(`/${this.selectedUserId()}/command/group/${group.id}/active`, {
      active: !group.active,
    });
  }

  static deleteBotCommandGroup (id) {
    return this.http.delete(`/${this.selectedUserId()}/command/group/${id}`);
  }

  static getBotUngroupedCommands () {
    return this.http.get(`/${this.selectedUserId()}/command`);
  }

  static getBotCommand (commandId) {
    return this.http.get(`/${this.selectedUserId()}/command/${commandId}`);
  }

  static postBotCommand (groupId, command) {
    return this.http.post(`/${this.selectedUserId()}/command/custom`, {
      ...command,
      groupId,
    });
  }

  static putBotCommand (commandId, groupId, command) {
    return this.http.put(`/${this.selectedUserId()}/command/custom/${commandId}`, {
      ...command,
      groupId,
    });
  }

  static postBotCommandInstalled (command) {
    return this.http.post(`/${this.selectedUserId()}/command/installed`, command);
  }

  static putBotCommandInstalled (commandId, groupId, command) {
    return this.http.put(`/${this.selectedUserId()}/command/installed/${commandId}`, {
      ...command,
      groupId,
    });
  }

  static patchBotCommandActive (commandId, active) {
    return this.http.patch(`/${this.selectedUserId()}/command/${commandId}/active`, {
      active,
    });
  }

  static deleteBotCommand (commandId) {
    return this.http.delete(`/${this.selectedUserId()}/command/${commandId}`);
  }

  static getBotDefaultCommands () {
    return this.http.get(`/${this.selectedUserId()}/default-command`);
  }

  static defaultCommandToggleActive (command) {
    return this.http.patch(`/${this.selectedUserId()}/default-command/${command.command}/active`, {
      active: !command.active,
    });
  }

  static patchBotDefaultCommand (command, patch) {
    return this.http.patch(`/${this.selectedUserId()}/default-command/${command}`, patch);
  }

  static getBotDefaultCommandSchema (command) {
    return this.http.get(`/default-command/schema/${command}`);
  }

  static moveBotCommand (commandId, groupId) {
    return this.http.patch(`/${this.selectedUserId()}/command/${commandId}/move`, {
      groupId,
    });
  }

  static getActiveCommandsPublic (userId) {
    return this.http.get(`/${userId}/command/public/active`);
  }

  static getBotPeriodicMessages () {
    return this.http.get(`/${this.selectedUserId()}/periodic-message`);
  }

  static getBotPeriodicMessage (id) {
    return this.http.get(`/${this.selectedUserId()}/periodic-message/${id}`);
  }

  static putBotPeriodicMessage (messageId, message) {
    return this.http.put(`/${this.selectedUserId()}/periodic-message/${messageId}`, message);
  }

  static postBotPeriodicMessage (message) {
    return this.http.post(`/${this.selectedUserId()}/periodic-message`, message);
  }

  static periodicMessageToggleActive (messageId, active) {
    return this.http.patch(`/${this.selectedUserId()}/periodic-message/${messageId}/active`, {
      active,
    });
  }

  static deletePeriodicMessage (id) {
    return this.http.delete(`/${this.selectedUserId()}/periodic-message/${id}`);
  }

  static getBotVariables () {
    return this.http.get(`/${this.selectedUserId()}/counter-variable`);
  }

  static deleteBotVariable (variable) {
    return this.http.delete(`/${this.selectedUserId()}/counter-variable/name/${variable.name}`);
  }

  static saveBotVariable (name, amount) {
    return this.http.patch(`/${this.selectedUserId()}/counter-variable/name/${name}/set`, {
      value: amount,
    });
  }

  static getMarketplaceCommandCategories () {
    return this.http.get('/marketplace/command/category');
  }

  static postMarketplaceCommandCategory (category) {
    return this.http.post('/marketplace/command/creator/category', category);
  }

  static putMarketplaceCommandCategory (id, category) {
    return this.http.put(`/marketplace/command/creator/category/${id}`, category);
  }

  static getMarketplaceCommands (page = 1, pageSize = 50, search = '', categories = []) {
    return this.http.get('/marketplace/command', {
      params: {
        page,
        pageSize,
        search,
        categories: categories.join(','),
      },
    });
  }

  static getMarketplaceCommandsTrending () {
    return this.http.get('/marketplace/command/trending');
  }

  static getMarketplaceCommandVersions (itemId) {
    return this.http.get(`/marketplace/command/${itemId}/version`);
  }

  static getMarketplaceCommandVersion (itemId, version = 'latest') {
    return this.http.get(`/marketplace/command/${itemId}/version/${version}`);
  }

  static postMarketplaceCommand (command) {
    return this.http.post('/marketplace/command/creator', command);
  }

  static putMarketplaceCommand (command) {
    return this.http.put(`/marketplace/command/creator/${command.id}`, command);
  }

  static getMarketplaceCreatorCommandVersion (item, version) {
    return this.http.get(`/marketplace/command/creator/${item.id}/version/${version}`);
  }

  static postMarketplaceCreatorCommandVersion (command, commandVersion) {
    return this.http.post(`/marketplace/command/creator/${command.id}/version`, commandVersion);
  }

  static getGlobalMessages () {
    return this.http.get(`/${this.selectedUserId()}/global-message`);
  }

  static getGlobalMessage (type) {
    return this.http.get(`/${this.selectedUserId()}/global-message/type/${type}`);
  }

  static globalMessageToggleActive (type, active) {
    return this.http.patch(`/${this.selectedUserId()}/global-message/type/${type}/active`, {
      active,
    });
  }

  static putGlobalMessage (message) {
    return this.http.put(`/${this.selectedUserId()}/global-message/type/${message.type}`, message);
  }

  static getAlertMessages () {
    return this.http.get(`/${this.selectedUserId()}/alert-message`);
  }

  static getAlertMessage (type) {
    return this.http.get(`/${this.selectedUserId()}/alert-message/type/${type}`);
  }

  static alertMessageToggleActive (type, active) {
    return this.http.patch(`/${this.selectedUserId()}/alert-message/type/${type}/active`, {
      active,
    });
  }

  static putAlertMessage (message) {
    return this.http.put(`/${this.selectedUserId()}/alert-message/type/${message.type}`, message);
  }

  static listModerationRules () {
    return this.http.get(`/${this.selectedUserId()}/moderation/rule`);
  }

  static getModerationRule (id) {
    return this.http.get(`/${this.selectedUserId()}/moderation/rule/${id}`);
  }

  static postModerationRule (rule) {
    return this.http.post(`/${this.selectedUserId()}/moderation/rule`, rule);
  }

  static putModerationRule (id, rule) {
    return this.http.put(`/${this.selectedUserId()}/moderation/rule/${id}`, rule);
  }

  static deleteModerationRule (id) {
    return this.http.delete(`/${this.selectedUserId()}/moderation/rule/${id}`);
  }

  static moderationRuleToggleActive (id, active) {
    return this.http.patch(`/${this.selectedUserId()}/moderation/rule/${id}/active`, {
      active,
    });
  }

  static moveModerationRule (id, position) {
    return this.http.patch(`/${this.selectedUserId()}/moderation/rule/${id}/move`, {
      position,
    });
  }

  static getModerationWhitelist () {
    return this.http.get(`/${this.selectedUserId()}/moderation/whitelist`);
  }

  static putModerationWhitelist (roles) {
    return this.http.put(`/${this.selectedUserId()}/moderation/whitelist`, {
      roles,
    });
  }

  static getAuditLogs (page = 1, pageSize = 10, orderBy = 'createdAt', order = 'desc', search) {
    return this.http.get(`/${this.selectedUserId()}/audit/log`, {
      params: {
        page,
        pageSize,
        orderBy,
        order,
        search,
      },
    });
  }

  static getStreamTitle () {
    return this.http.get(`/${this.selectedUserId()}/stream-title`);
  }

  static putStreamTitle (active, title) {
    return this.http.put(`/${this.selectedUserId()}/stream-title`, {
      active,
      title,
    });
  }

  static streamTitleToggleActive (active) {
    return this.http.patch(`/${this.selectedUserId()}/stream-title/active`, {
      active,
    });
  }

  static getGiveaway (id) {
    return this.http.get(`/${this.selectedUserId()}/giveaway/${id}`);
  }

  static listGiveaways (pageSize, page, state) {
    return this.http.get(`/${this.selectedUserId()}/giveaway`, {
      params: {
        pageSize,
        page,
        state,
      },
    });
  }

  static listGiveawayEntries (id, pageSize, page, state, search) {
    return this.http.get(`/${this.selectedUserId()}/giveaway/${id}/entry`, {
      params: {
        pageSize,
        page,
        state,
        search,
      },
    });
  }

  static exportGiveawayEntries (id, timestamp = false) {
    return this.http.get(`/${this.selectedUserId()}/giveaway/${id}/entry/export`, {
      params: {
        timestamp,
      },
    });
  }

  static postGiveaway (giveaway) {
    return this.http.post(`/${this.selectedUserId()}/giveaway`, giveaway);
  }

  static clearGiveawayEntries (id, state) {
    return this.http.delete(`/${this.selectedUserId()}/giveaway/${id}/entry`, {
      params: {
        state,
      },
    });
  }

  static deleteGiveawayEntry (id, entryId) {
    return this.http.delete(`/${this.selectedUserId()}/giveaway/${id}/entry/${entryId}`);
  }

  static stopGiveaway (id) {
    return this.http.patch(`/${this.selectedUserId()}/giveaway/${id}/stop`);
  }

  static giveawayRoll (id) {
    return this.http.put(`/${this.selectedUserId()}/giveaway/${id}/roll`);
  }

  static giveawayReRoll (id) {
    return this.http.put(`/${this.selectedUserId()}/giveaway/${id}/re-roll`);
  }

  static getKickIdentitySettings () {
    return this.http.get(`/${this.selectedUserId()}/kick/identity`);
  }

  static putKickIdentitySettings (settings) {
    return this.http.put(`/${this.selectedUserId()}/kick/identity`, settings);
  }

  static getLimits () {
    return this.http.get(`/${this.userId()}/limit`);
  }

  static getUsage () {
    return this.http.get(`/${this.userId()}/usage`);
  }

  static stripeIsCustomer () {
    return this.http.get('/stripe/is-customer');
  }

  static stripeCheckoutPremium (price) {
    return this.http.post('/stripe/checkout-premium', {
      price,
    });
  }

  static stripeBillingPortal () {
    return this.http.get('/stripe/billing-portal');
  }

  static stripePremiumConfig () {
    return this.http.get('/stripe/subscription/config');
  }

  static stripeSubscription () {
    return this.http.get('/stripe/subscription');
  }

  static cancelStripeSubscription () {
    return this.http.delete('/stripe/subscription');
  }

  static getShopSettings () {
    return this.http.get(`/${this.selectedUserId()}/shop/settings`);
  }

  static getPublicShopSettings (user) {
    return this.http.get(`/shop/${user}/settings`);
  }

  static toggleShopActive (active) {
    return this.http.patch(`/${this.selectedUserId()}/shop/active`, {
      active,
    });
  }

  static putShopSettings (settings) {
    return this.http.put(`/${this.selectedUserId()}/shop/settings`, settings);
  }

  static getShopItems (page = 1, pageSize = 100) {
    return this.http.get(`/${this.selectedUserId()}/shop/item`, {
      params: {
        page,
        pageSize,
      },
    });
  }

  static getShopItem (id) {
    return this.http.get(`/${this.selectedUserId()}/shop/item/${id}`);
  }

  static postShopItem (item) {
    return this.http.post(`/${this.selectedUserId()}/shop/item`, item);
  }

  static putShopItem (id, item) {
    return this.http.put(`/${this.selectedUserId()}/shop/item/${item.id}`, item);
  }

  static shopItemToggleActive (id, active) {
    return this.http.patch(`/${this.selectedUserId()}/shop/item/${id}/active`, {
      active,
    });
  }

  static shopItemSetPosition (id, position) {
    return this.http.patch(`/${this.selectedUserId()}/shop/item/${id}/position`, {
      position,
    });
  }

  static deleteShopItem (id) {
    return this.http.delete(`/${this.selectedUserId()}/shop/item/${id}`);
  }

  static getShopStats () {
    return this.http.get(`/${this.selectedUserId()}/shop/stats/total`);
  }

  static getShopStatsChart () {
    return this.http.get(`/${this.selectedUserId()}/shop/stats/chart`);
  }

  static getShopSales (page, pageSize, search, filterOrder, filterDelivered) {
    return this.http.get(`/${this.selectedUserId()}/shop/sale`, {
      params: {
        page,
        pageSize,
        search,
        filterOrder,
        filterDelivered,
      },
    });
  }

  static exportShopSales (summarize, filterOrder, filterDelivered) {
    return this.http.get(`/${this.selectedUserId()}/shop/sale/export`, {
      params: {
        summarize,
        filterOrder,
        filterDelivered,
      },
    });
  }

  static toggleShopSaleDelivered (id, delivered) {
    return this.http.patch(`/${this.selectedUserId()}/shop/sale/${id}/delivered`, {
      delivered,
    });
  }

  static getPublicShopItems (user, page = 1, pageSize = 100) {
    return this.http.get(`/shop/${user}/item`, {
      params: {
        page,
        pageSize,
      },
    });
  }

  static getShopItemStats (id) {
    return this.http.get(`/${this.selectedUserId()}/shop/item/${id}/stats/total`);
  }

  static getShopItemStatsChart (id) {
    return this.http.get(`/${this.selectedUserId()}/shop/item/${id}/stats/chart`);
  }

  static createShopSession (itemId, customFields) {
    return this.http.post('/shop/session', {
      itemId,
      customFields,
    });
  }

  static listVariables (page, pageSize, search) {
    return this.http.get(`/${this.selectedUserId()}/variable`, {
      params: {
        page,
        pageSize,
        search,
      },
    });
  }

  static listViewerVariableNames (page, pageSize, search) {
    return this.http.get(`/${this.selectedUserId()}/variable/viewer/overview`, {
      params: {
        page,
        pageSize,
        search,
      },
    });
  }

  static listViewerVariables (variable, page, pageSize, search) {
    return this.http.get(`/${this.selectedUserId()}/variable/viewer/list/${variable}`, {
      params: {
        page,
        pageSize,
        search,
      },
    });
  }

  static setVariable (variable) {
    return this.http.put(`/${this.selectedUserId()}/variable`, variable);
  }

  static deleteVariable (deletion) {
    return this.http.delete(`/${this.selectedUserId()}/variable`, {
      data: deletion,
    });
  }

  static handleError (err) {
    const response = err.response;
    if (!response) return;

    const data = response.data;
    if (data) {
      if (data.error) {
        if (data.error === 'validation') {
          store.commit('displayError', {
            text: `backend.errors.${data.error}`,
            data: {
              messages: '<ul>' + data.messages.map(function (message) {
                return '<li>' + message + '</li>';
              }).join('') + '</ul>',
            },
          });
        } else {
          store.commit('displayError', { text: `backend.errors.${data.error}` });
          return;
        }
      }
      console.log(data);
      return;
    }

    if (err.response.status === 403) {
      store.commit('displayError', { text: 'backend.errors.forbidden' });
      return;
    }

    console.log(err);
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          backend: Backend,
        };
      },
    });
  },
};
