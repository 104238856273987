import { createRouter, createWebHistory } from 'vue-router';
import { Analytics } from '@/services/Analytics';
import store from '@/store';

import widgets from '@/router/widgets';
import commands from '@/router/commands';
import profile from '@/router/profile';
import stats from '@/router/stats';
import giveaway from '@/router/giveaway';
import shop from '@/router/shop';

const Home = () => import('@/views/Home');
const Admin = () => import('@/views/Admin');
const Collaborators = () => import('@/views/Collaborators');
const Panels = () => import('@/views/Panels');
const Emotes = () => import('@/views/Emotes');
const MultiStream = () => import('@/views/MultiStream');
const UserLookUp = () => import('@/views/UserLookUp');
const Commands = () => import('@/views/commands/Commands');
const GlobalMessages = () => import('@/views/GlobalMessages');
const PeriodicMessages = () => import('@/views/PeriodicMessages');
const Moderation = () => import('@/views/moderation/Moderation.vue');
const AlertMessages = () => import('@/views/AlertMessages');
const SongRequests = () => import('@/views/SongRequests.vue');
const PointSettings = () => import('@/views/PointSettings');
const Variables = () => import('@/views/variables/Variables');
const SignInProgress = () => import('@/views/SignInProgress');
const LoginRequired = () => import('@/views/LoginRequired');
const Profile = () => import('@/views/profile/Profile');
const AdvancedEditor = () => import('@/views/AdvancedEditor');
const PublicProfile = () => import('@/views/PublicProfile');
const PublicShop = () => import('@/views/shop/PublicShop');
const Extension = () => import('@/views/Extension');
const VodDownloader = () => import('@/views/VodDownloader.vue');
const ChatDownloader = () => import('@/views/ChatDownloader.vue');
const StripeReturn = () => import('@/views/StripeReturn.vue');
const TermsOfService = () => import('@/views/TermsOfService.vue');
const Dashboard = () => import('@/views/Dashboard.vue');
const Shop = () => import('@/views/shop/Shop.vue');

const NotFound = () => import('@/views/NotFound');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresNoAuth: {
        redirect: '/dashboard',
      },
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresGroup: ['admin'],
    },
  },
  {
    path: '/collaborators',
    name: 'Collaborators',
    component: Collaborators,
    meta: {
      breadcrumb: 'collaborators.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/panels',
    name: 'Panels',
    meta: {
      breadcrumb: 'panels.title',
    },
    component: Panels,
  },
  {
    path: '/emotes',
    name: 'Emotes',
    meta: {
      breadcrumb: 'emotes.title',
    },
    component: Emotes,
  },
  {
    path: '/extension',
    name: 'Extension',
    meta: {
      breadcrumb: 'extension.title',
    },
    component: Extension,
  },
  {
    path: '/vod-downloader',
    name: 'VodDownloader',
    meta: {
      breadcrumb: 'vod-downloader.title',
    },
    component: VodDownloader,
  },
  {
    path: '/chat-downloader',
    name: 'ChatDownloader',
    meta: {
      breadcrumb: 'chat-downloader.title',
    },
    component: ChatDownloader,
  },
  {
    path: '/multi-stream',
    name: 'MultiStream',
    meta: {
      breadcrumb: 'multi-stream.title',
    },
    component: MultiStream,
  },
  {
    path: '/user-lookup/:streamer?',
    name: 'UserLookUp',
    meta: {
      breadcrumb: 'user-lookup.title',
    },
    component: UserLookUp,
  },
  {
    path: '/widgets',
    children: widgets,
    meta: {
      breadcrumb: 'Widgets',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/commands',
    name: 'Commands',
    component: Commands,
    children: commands,
    meta: {
      breadcrumb: 'bot.commands.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/global-messages',
    name: 'GlobalMessages',
    component: GlobalMessages,
    meta: {
      breadcrumb: 'bot.global-messages.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/periodic-messages',
    name: 'PeriodicMessages',
    component: PeriodicMessages,
    meta: {
      breadcrumb: 'bot.periodic-messages.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/moderation',
    name: 'Moderation',
    component: Moderation,
    meta: {
      breadcrumb: 'bot.moderation.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/alert-messages',
    name: 'AlertMessages',
    component: AlertMessages,
    meta: {
      breadcrumb: 'bot.alert-messages.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/song-requests',
    name: 'SongRequests',
    component: SongRequests,
    meta: {
      breadcrumb: 'bot.song-requests.title-menu',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/point-settings',
    name: 'PointSettings',
    component: PointSettings,
    meta: {
      breadcrumb: 'bot.points.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/variables',
    name: 'Variables',
    component: Variables,
    meta: {
      breadcrumb: 'variables',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/stats',
    children: stats,
    meta: {
      breadcrumb: 'bot.stats.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/giveaway',
    children: giveaway,
    meta: {
      breadcrumb: 'giveaway.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    children: profile,
    meta: {
      breadcrumb: 'private-profile.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/advanced-editor',
    name: 'BotAdvancedEditor',
    component: AdvancedEditor,
    meta: {
      requiresAuth: {
        redirect: '/login-required',
      },
      hideNavigation: true,
    },
  },
  {
    path: '/user/:streamer/shop',
    name: 'PublicShop',
    component: PublicShop,
  },
  {
    path: '/user/:streamer/:profileTab?',
    name: 'PublicProfile',
    component: PublicProfile,
  },
  {
    path: '/auth',
    name: 'SignIn',
    component: SignInProgress,
  },
  {
    path: '/login-required',
    component: LoginRequired,
    meta: {
      requiresNoAuth: {
        redirect: '/',
      },
    },
  },
  {
    path: '/ranking/:streamer',
    redirect: to => {
      const { params } = to;
      const { streamer } = params;
      return { name: 'PublicProfile', params: { streamer, profileTab: 'ranking' } };
    },
  },
  {
    path: '/stripe-return',
    name: 'StripeReturn',
    component: StripeReturn,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      breadcrumb: 'dashboard.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/shop',
    component: Shop,
    children: shop,
    meta: {
      breadcrumb: 'shop.title',
      requiresAuth: {
        redirect: '/login-required',
      },
    },
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: to.meta.requiresAuth.redirect || '/',
        query: { redirect: encodeURIComponent(to.fullPath) },
      });
      return;
    }
  } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (store.getters.isLoggedIn) {
      next({
        path: to.meta.requiresNoAuth.redirect || '/',
      });
      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresGroup)) {
    if (!store.getters.isLoggedIn || !store.getters.tokenHasSomeGroup(to.meta.requiresGroup)) {
      next({
        path: to.meta.requiresGroup.redirect || '/',
      });
      return;
    }
  }

  next();
});

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    Analytics.pageView(to.name, to.path);
  }
});

export default router;
