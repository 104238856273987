export default {
  "accept-tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the Terms of Service"])},
  "add-to-stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to stream"])},
  "admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "advanced-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Editor"])},
  "assets.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the Asset"])},
  "assets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
  "audit-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Logs"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "backend.errors.BOT_COMMAND_ALIAS_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> is already an existing command"])},
  "backend.errors.BOT_COMMAND_ALIAS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of your aliases is empty"])},
  "backend.errors.BOT_COMMAND_ALIAS_SELF_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> is the same as your command"])},
  "backend.errors.BOT_COMMAND_MIN_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your command requires at least one permission"])},
  "backend.errors.already canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription has already been terminated and will continue only for the period you have already paid for."])},
  "backend.errors.command already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have already another command with that given name"])},
  "backend.errors.config limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character limit exceeded."])},
  "backend.errors.forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not allowed to do that"])},
  "backend.errors.giveaway already ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The giveaway has already ended"])},
  "backend.errors.invalid JSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred."])},
  "backend.errors.limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit."])},
  "backend.errors.max storage size exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file cannot be uploaded as it would exceed the storage limit."])},
  "backend.errors.moderation rule name already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rule with this name already exists"])},
  "backend.errors.no entries to roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No entries to roll"])},
  "backend.errors.premium only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is currently only available for Premium users."])},
  "backend.errors.user dont activate collaboration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user has not activated the collaboration"])},
  "backend.errors.validation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Validation failed! ", _interpolate(_named("messages"))])},
  "backend.errors.version already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This version already exists"])},
  "bot.alert-messages.FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "bot.alert-messages.HOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "bot.alert-messages.SUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "bot.alert-messages.SUB_GIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gifted subscriptions"])},
  "bot.alert-messages.UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
  "bot.alert-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate the message"])},
  "bot.alert-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
  "bot.alert-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert Messages"])},
  "bot.command-group.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Command Group - ", _interpolate(_named("name"))])},
  "bot.command-groups.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate the command group"])},
  "bot.command-groups.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
  "bot.command-groups.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["the Command Group <span class='color-primary'>", _interpolate(_named("name")), "</span> and all contained commands"])},
  "bot.command-groups.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Group"])},
  "bot.command-groups.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Group"])},
  "bot.command-groups.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Command Groups (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.command-groups.labels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "bot.command-groups.labels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "bot.command-groups.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not have more command groups"])},
  "bot.command-groups.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot create"])},
  "bot.command-groups.no-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no groups. Starting with creating a new."])},
  "bot.command-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Groups"])},
  "bot.command.errors.optional-after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No arguments after that should be required!"])},
  "bot.command.errors.required-before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All arguments before must be required for that!"])},
  "bot.command.labels.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "bot.command.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Command - !", _interpolate(_named("command"))])},
  "bot.commands.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate a command"])},
  "bot.commands.aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aliases"])},
  "bot.commands.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Command"])},
  "bot.commands.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
  "bot.commands.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["the Command <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "bot.commands.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
  "bot.commands.detail-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Command - ", _interpolate(_named("name"))])},
  "bot.commands.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Commands (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.commands.import-from-marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import from the Marketplace"])},
  "bot.commands.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "bot.commands.no-aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No aliases"])},
  "bot.commands.no-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no commands in this group. Starting with creating a new."])},
  "bot.commands.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])},
  "bot.commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "bot.creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "bot.default-commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Commands"])},
  "bot.gallery.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "bot.global-messages.NOT_ENOUGH_POINTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough points"])},
  "bot.global-messages.NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permission"])},
  "bot.global-messages.PERIOD_NOT_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command cooldown"])},
  "bot.global-messages.USABILITY_NOT_MATCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream live state not matched"])},
  "bot.global-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "bot.global-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Messages"])},
  "bot.marketplace-creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace Creator"])},
  "bot.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "bot.moderation-rule.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate the rule"])},
  "bot.moderation.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Rule"])},
  "bot.moderation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation"])},
  "bot.periodic-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate the message"])},
  "bot.periodic-messages.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Message"])},
  "bot.periodic-messages.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this periodic message"])},
  "bot.periodic-messages.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic Message"])},
  "bot.periodic-messages.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Message"])},
  "bot.periodic-messages.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Periodic Messages (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.periodic-messages.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not have more command groups"])},
  "bot.periodic-messages.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot create"])},
  "bot.periodic-messages.no-periodic-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no periodic messages. Starting with creating a new."])},
  "bot.periodic-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic Messages"])},
  "bot.points.points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points"])},
  "bot.points.points-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points Name"])},
  "bot.points.points-per-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points per message"])},
  "bot.points.points-per-watchtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points per minute watchtime"])},
  "bot.points.subs-multiplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber Multiplier"])},
  "bot.points.subscriber-bonus-points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your subscribers will receive points x", _interpolate(_named("multiplier")), "."])},
  "bot.points.subscriber-same-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscribers will receive the same amount of points as any other viewer."])},
  "bot.points.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points System"])},
  "bot.song-requests.no-songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No songs have been added yet. You can add some in your chat or have your viewers add them using the command !sr (YT-URL)"])},
  "bot.song-requests.now-playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOW PLAYING:"])},
  "bot.song-requests.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Song Requests (", _interpolate(_named("min")), "/", _interpolate(_named("max")), ")"])},
  "bot.song-requests.title-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song Requests"])},
  "bot.songrequest-command.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate the Song request Command"])},
  "bot.stats.charts.chat-activities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat activities"])},
  "bot.stats.charts.command-executions-pie.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command executions"])},
  "bot.stats.charts.command-executions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command executions"])},
  "bot.stats.errors.too-much-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too much data. Try to select a smaller range or a larger interval."])},
  "bot.stats.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
  "bot.stats.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin your first livestream to gather statistics about your streams."])},
  "bot.stats.range.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 4 Weeks"])},
  "bot.stats.range.week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 Days"])},
  "bot.stats.range.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year"])},
  "bot.stats.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
  "bot.stats.viewer.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit viewer"])},
  "bot.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Bot"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
  "chat-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Downloader"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
  "clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear all"])},
  "click-an-emote-to-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click an emote to copy"])},
  "collaborators.dialog.add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Collaborator"])},
  "collaborators.dialog.delete-managed-users.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "collaborators.dialog.delete-managed-users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managed User"])},
  "collaborators.dialog.edit.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Edit the permissions of <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>."])},
  "collaborators.dialog.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Collaborator"])},
  "collaborators.dialog.permissions.alert-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert Messages"])},
  "collaborators.dialog.permissions.audit-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit-Log"])},
  "collaborators.dialog.permissions.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "collaborators.dialog.permissions.counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter Variables"])},
  "collaborators.dialog.permissions.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "collaborators.dialog.permissions.danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚠️ Danger"])},
  "collaborators.dialog.permissions.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "collaborators.dialog.permissions.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "collaborators.dialog.permissions.execute-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute Code"])},
  "collaborators.dialog.permissions.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "collaborators.dialog.permissions.giveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway"])},
  "collaborators.dialog.permissions.global-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Messages"])},
  "collaborators.dialog.permissions.kick-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity"])},
  "collaborators.dialog.permissions.moderation-chat-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation Rules"])},
  "collaborators.dialog.permissions.overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlays"])},
  "collaborators.dialog.permissions.periodic-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic Messages"])},
  "collaborators.dialog.permissions.shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "collaborators.dialog.permissions.song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song Requests"])},
  "collaborators.dialog.permissions.stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
  "collaborators.dialog.permissions.stream-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Title"])},
  "collaborators.dialog.permissions.test-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Events"])},
  "collaborators.dialog.permissions.toggle-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle"])},
  "collaborators.dialog.permissions.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "collaborators.dialog.permissions.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
  "collaborators.dialog.permissions.view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "collaborators.dialog.remove.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "collaborators.dialog.remove.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "collaborators.dialog.remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> from your collaborators?"])},
  "collaborators.dialog.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Collaborator"])},
  "collaborators.managed.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Kicklet as"])},
  "collaborators.menutitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
  "collaborators.table.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "collaborators.table.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "collaborators.table.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "collaborators.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborators"])},
  "collaborators.toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other users can add me as a collaborator"])},
  "command-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
  "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter variable"])},
  "counter-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter variables"])},
  "coupon-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon (optional)"])},
  "dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "dialog.abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
  "dialog.are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure?"])},
  "dialog.buy.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now"])},
  "dialog.buy.inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the required information"])},
  "dialog.buy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase Item"])},
  "dialog.cancel-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceling will end premium features after this billing period. For questions or help, contact us on Discord. Confirm below to proceed. Thank you!"])},
  "dialog.command-config.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure Command"])},
  "dialog.command-move.select-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Group"])},
  "dialog.command-move.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move Command"])},
  "dialog.confirm.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "dialog.confirm.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed? This action cannot be undone."])},
  "dialog.confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm the action"])},
  "dialog.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "dialog.delete.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "dialog.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("item")), " permanently?"])},
  "dialog.delete.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("item"))])},
  "dialog.giveaway.chat-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Message"])},
  "dialog.giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs"])},
  "dialog.giveaway.entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry"])},
  "dialog.giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivity Timeout"])},
  "dialog.giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
  "dialog.giveaway.output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Output"])},
  "dialog.giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway"])},
  "dialog.logout.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "dialog.logout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to log out?"])},
  "dialog.logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "dialog.moderation-rule.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "dialog.moderation-rule.action-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "dialog.moderation-rule.ignore-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore case"])},
  "dialog.moderation-rule.ignore-emote-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Emote Only"])},
  "dialog.moderation-rule.min-characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Characters"])},
  "dialog.moderation-rule.min-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Messages"])},
  "dialog.moderation-rule.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "dialog.moderation-rule.similar-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similarity Check"])},
  "dialog.moderation-rule.similar-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
  "dialog.moderation-rule.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule settings"])},
  "dialog.moderation-rule.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
  "dialog.moderation-rule.trigger-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "dialog.moderation-rule.within-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeframe"])},
  "dialog.ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "dialog.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "dialog.shop.custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Fields"])},
  "dialog.shop.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Details"])},
  "dialog.shop.item-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Action"])},
  "dialog.shop.item-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Configuration"])},
  "discard-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
  "discord.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "docs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentation"])},
  "downloader.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "downloader.end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["End: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length:"])},
  "downloader.messages-downloaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Messages downloaded: ", _interpolate(_named("messagesDownloaded"))])},
  "downloader.quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality"])},
  "downloader.start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
  "downloader.time-elapsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Time elapsed: ", _interpolate(_named("formatVideoTime"))])},
  "downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title:"])},
  "downloader.url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert a valid Kick Video URL"])},
  "downloader.video-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Details:"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editing-as-username": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editing as ", _interpolate(_named("username"))])},
  "emote-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The emote has been copied to your clipboard."])},
  "emotes.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "emotes.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "emotes.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
  "emotes.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "emotes.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow"])},
  "emotes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emote Generator"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "event.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "event.gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gifted Sub"])},
  "event.host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "event.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub"])},
  "event.unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
  "extension-advantages": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Chat: Disables Kicklet messages for readability."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Overview: Shows all commands with \"!\"."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhanced Interaction: Boosts viewer engagement."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User-friendly: Simple for viewers."])}
  ],
  "extension.features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advantages"])},
  "extension.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser Extension"])},
  "file.invalid-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> is not a valid file type!"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forever"])},
  "form.addcollab.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.addcollab.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "form.command-group.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "form.command-group.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.aliases.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliases"])},
  "form.command.aliases.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliases"])},
  "form.command.aliases.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.command.arguments.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arguments"])},
  "form.command.arguments.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Named Arguments"])},
  "form.command.arguments.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.arguments.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
  "form.command.asResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Response"])},
  "form.command.availability.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
  "form.command.category.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "form.command.changelog.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
  "form.command.command.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
  "form.command.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
  "form.command.config.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
  "form.command.config.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Config"])},
  "form.command.costs.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs"])},
  "form.command.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "form.command.examples.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examples"])},
  "form.command.examples.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "form.command.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Period"])},
  "form.command.image.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.command.messageResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Response"])},
  "form.command.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.command.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "form.command.template.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])},
  "form.command.usage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
  "form.command.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Period"])},
  "form.command.version.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "form.counter-variable.counter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
  "form.counter-variable.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "form.overlay-alert-part.gif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
  "form.overlay-alert-part.sound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "form.overlay-alert-part.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.overlay-alert-part.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time in seconds"])},
  "form.overlay-alert-part.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.overlay-alert.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontsize"])},
  "form.overlay-alert.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary Color"])},
  "form.overlay-alert.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary Color"])},
  "form.overlay-alert.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Shadow"])},
  "form.overlay-alert.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text over GIF"])},
  "form.periodic-message.message.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "form.periodic-message.messagesThreshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Messages"])},
  "form.periodic-message.period.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "form.periodic-message.pinMessage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pin message"])},
  "form.shop.settings.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command Label"])},
  "form.shop.settings.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Period"])},
  "form.shop.settings.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Period"])},
  "form.widget.animated.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animated"])},
  "form.widget.animation.fab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotate"])},
  "form.widget.animation.fade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fade In"])},
  "form.widget.animation.floating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floating"])},
  "form.widget.animation.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])},
  "form.widget.animation.scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlarge"])},
  "form.widget.animation.slide-x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide from Left"])},
  "form.widget.animation.slide-x-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide from Right"])},
  "form.widget.animation.slide-y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide from Top"])},
  "form.widget.animation.slide-y-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slide from Bottom"])},
  "form.widget.animation.snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow"])},
  "form.widget.animationDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation Duration"])},
  "form.widget.appearance.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
  "form.widget.backgroundColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color"])},
  "form.widget.badges.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show badges"])},
  "form.widget.bars.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bars"])},
  "form.widget.barsRatio.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bars Ratio"])},
  "form.widget.bold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bold"])},
  "form.widget.border.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Border"])},
  "form.widget.borderColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Border color"])},
  "form.widget.bots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Bot messages"])},
  "form.widget.color.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "form.widget.cooldownPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown Period (seconds)"])},
  "form.widget.defaultCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Commands"])},
  "form.widget.displayDelay.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Delay"])},
  "form.widget.displayDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Duration"])},
  "form.widget.duration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "form.widget.emoteSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emote size"])},
  "form.widget.flameIcon.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flame"])},
  "form.widget.follow.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "form.widget.followActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "form.widget.followDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (seconds)"])},
  "form.widget.followFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Screen Graphic"])},
  "form.widget.followGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.widget.followPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.followPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Margin"])},
  "form.widget.followPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Margin"])},
  "form.widget.followSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "form.widget.followText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.followVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.widget.font.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
  "form.widget.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontsize"])},
  "form.widget.goal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
  "form.widget.horizontal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "form.widget.host.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "form.widget.hostActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "form.widget.hostDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (seconds)"])},
  "form.widget.hostFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Screen Graphic"])},
  "form.widget.hostGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.widget.hostPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.hostPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Margin"])},
  "form.widget.hostPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Margin"])},
  "form.widget.hostSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "form.widget.hostText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.hostVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.widget.hypeTrainOverText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.ignoreBots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Bots"])},
  "form.widget.ignoreCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Commands"])},
  "form.widget.ignoreEmojis.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Emojis"])},
  "form.widget.items.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "form.widget.items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "form.widget.levelCompletedText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.levelGoals.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Goals difficulty"])},
  "form.widget.limit.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
  "form.widget.loadOldMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load old messages"])},
  "form.widget.max.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "form.widget.minStreak.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum streak"])},
  "form.widget.multiplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplier"])},
  "form.widget.noSongSubTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Song Subtitle"])},
  "form.widget.noSongTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Song Title"])},
  "form.widget.participateText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.pauseDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause Duration"])},
  "form.widget.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
  "form.widget.pill.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pill"])},
  "form.widget.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primary color"])},
  "form.widget.readUsername.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Username"])},
  "form.widget.removeMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Older Messages"])},
  "form.widget.removeMessagesAfter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delay (Seconds)"])},
  "form.widget.roles.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "form.widget.rounded.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rounded"])},
  "form.widget.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secondary color"])},
  "form.widget.sevenTV.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show SevenTV Emotes"])},
  "form.widget.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow"])},
  "form.widget.showImage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Image"])},
  "form.widget.showNumbers.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show numbers"])},
  "form.widget.showPercentage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show percentage"])},
  "form.widget.showProfilePicture.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show profile picture"])},
  "form.widget.showProgress.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show progress"])},
  "form.widget.showTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display time"])},
  "form.widget.showVideo.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Video"])},
  "form.widget.showVotes.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show votes"])},
  "form.widget.split.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Split"])},
  "form.widget.striped.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stripes"])},
  "form.widget.sub.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub"])},
  "form.widget.subActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "form.widget.subDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (seconds)"])},
  "form.widget.subFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Screen Graphic"])},
  "form.widget.subGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.widget.subGift.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Gift"])},
  "form.widget.subGiftActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "form.widget.subGiftDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (seconds)"])},
  "form.widget.subGiftFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Screen Graphic"])},
  "form.widget.subGiftGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.widget.subGiftPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.subGiftPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Margin"])},
  "form.widget.subGiftPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Margin"])},
  "form.widget.subGiftSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "form.widget.subGiftText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.subGiftVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.widget.subPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absolute Position"])},
  "form.widget.subPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left Margin"])},
  "form.widget.subPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Margin"])},
  "form.widget.subSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "form.widget.subText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.subTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle"])},
  "form.widget.subVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.widget.subscriptionsText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.supportText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.switchTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching Interval (seconds)"])},
  "form.widget.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.textColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text color"])},
  "form.widget.textShadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text shadow"])},
  "form.widget.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Top"])},
  "form.widget.threshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold"])},
  "form.widget.ticksColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticks color"])},
  "form.widget.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "form.widget.title.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "form.widget.titleText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.trackTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track time"])},
  "form.widget.useIdentityColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use identity color"])},
  "form.widget.voice.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice"])},
  "form.widget.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "form.widget.windowName.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Window Name"])},
  "form.widget.words.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Words"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
  "gifted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gifted"])},
  "giveaway.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costs:"])},
  "giveaway.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Giveaway"])},
  "giveaway.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "giveaway.end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "giveaway.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entries"])},
  "giveaway.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "giveaway.export.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "giveaway.export.timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Include timestamp"])},
  "giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivity Timeout:"])},
  "giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword:"])},
  "giveaway.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message:"])},
  "giveaway.no-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users are not disqualified from the giveaway due to inactivity."])},
  "giveaway.reroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-Roll"])},
  "giveaway.roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roll"])},
  "giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaway"])},
  "giveaway.winners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winners"])},
  "global-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Settings"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
  "go-premium-now-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Premium Now"])},
  "graphic-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic Design"])},
  "home.charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyze Your Audience Chat Activity with Kicklet"])},
  "home.charts-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual Charts for In-Depth Command and Messaging Insights"])},
  "home.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endless Gallery of Streaming Assets"])},
  "home.gallery-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximize your stream with our diverse collection of pre-programmed widgets and commands, including everything from minigames to unique interactive features."])},
  "home.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamers making the switch to Kicklet."])},
  "home.js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program Custom Commands Using JavaScript"])},
  "home.js-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevate Your Chat Experience with custom Scripting Solutions"])},
  "home.kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The best chatbot for Kick?"])},
  "home.kicklet-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicklet is recognized as the best chatbot for Kick, ideal for streamers across all levels. With our innovative features and customizable overlay widgets, we enhance every stream and stand out as a community favorite."])},
  "home.offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we offer:"])},
  "home.sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover why!"])},
  "home.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "home.trusted-streamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amazing streamers who trust in us"])},
  "home.what-is-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Kicklet?"])},
  "home.widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Overlay Widgets"])},
  "home.widgets-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our widgets are unique and highly customizable, allowing you to give your stream its own distinct identity."])},
  "home.youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about Kicklet in our YouTube video!"])},
  "home.youtube-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch our short video to get a feel for Kicklet, our chatbot."])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOT"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
  "join-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us on our Discord!"])},
  "kick-video-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick Video URL"])},
  "kick.identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity"])},
  "latest.events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Events"])},
  "latest.livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Livestream"])},
  "login-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Required"])},
  "login-required-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access this page, a login is required. You can easily log in using Discord and get started."])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "moderation-rule.action.BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
  "moderation-rule.action.DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "moderation-rule.action.TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "moderation-rule.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["the rule <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "moderation-rule.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule"])},
  "moderation-rule.no-longer-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This moderation rule no longer exists!"])},
  "moderation-rule.trigger.CONTAINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains"])},
  "moderation-rule.trigger.ENDS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ends With"])},
  "moderation-rule.trigger.EQUALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equals"])},
  "moderation-rule.trigger.REGEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Expression"])},
  "moderation-rule.trigger.SPAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spam"])},
  "moderation-rule.trigger.STARTS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starts With"])},
  "moderation-rule.trigger.UPPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper Case"])},
  "moderation-rule.trigger.URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Links"])},
  "moderation-rule.trigger.URL.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, all links are blocked. You can add exceptions below in the format \"kick.com\""])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
  "multi-stream.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch multiple streamers together with one generated link. Perfect for co-streaming or group gameplay. Enjoy seamless multi-streaming."])},
  "multi-stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Stream"])},
  "navigation.commandsAndTimers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands and Timers"])},
  "navigation.dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Management"])},
  "navigation.graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic Design"])},
  "navigation.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "navigation.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "navigation.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
  "navigation.userInteraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Interaction"])},
  "navigation.utils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utils"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW"])},
  "no-collab-permission-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The streamer has not given you the necessary permissions to view this."])},
  "no-latest-livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not streamed since Kicklet has been active. After your first stream, you will see the statistics for your livestream here."])},
  "no-more-widgets-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more widgets available"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "origin-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin data"])},
  "origin-data-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the box to reset to Kicklet's tracked origin data, leave unchecked to reset to zero."])},
  "page-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found."])},
  "panels.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "panels.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "panels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below, you can customize all the settings for your panels, and with just a click on the 'Generate' button, easily download your panel. Create all the panels you need in a matter of seconds and completely free of charge. If you have any suggestions for different shapes, fonts, or anything else, feel free to make a proposal on our Discord server."])},
  "panels.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font"])},
  "panels.labels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon"])},
  "panels.labels.panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "panels.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "panels.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shadow"])},
  "panels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel Generator"])},
  "periodic-messages-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic Message"])},
  "point-settings-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The point settings have been saved"])},
  "premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview:"])},
  "private-profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profile.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command"])},
  "profile.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "profile.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "profile.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profile.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
  "profile.song-queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Song Queue"])},
  "profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profile.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "public.profile.button.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Public Profile"])},
  "public.profile.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public profile Settings"])},
  "public.profile.toggle.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Commands Page"])},
  "public.profile.toggle.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Ranking Page"])},
  "public.profile.toggle.songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Song Queue Page"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchased"])},
  "ranking.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
  "ranking.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no data that can be displayed about this streamer's viewers"])},
  "ranking.no-streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer not found"])},
  "ranking.subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Viewer"])},
  "ranking.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏆 Ranking 🏆"])},
  "recent-livestreams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Livestreams"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "save-20-anually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 20% anually"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
  "saved-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved successfully"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "search-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search username.."])},
  "search.no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your search has not given any result."])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "shop.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Shop"])},
  "shop.copy-and-use-command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and Use Command"])},
  "shop.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "shop.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "shop.export.summarize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summarize same sales by customer"])},
  "shop.export.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "shop.item.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "shop.item.action.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "shop.item.action.custom-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Event"])},
  "shop.item.action.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "shop.item.action.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "shop.item.action.message-conflict-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a conflict with this action."])},
  "shop.item.action.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
  "shop.item.action.unique-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action can only be performed once."])},
  "shop.item.actionDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Duration"])},
  "shop.item.actionVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Volume"])},
  "shop.item.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "shop.item.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Command"])},
  "shop.item.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Description"])},
  "shop.item.event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Event"])},
  "shop.item.globalPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Period"])},
  "shop.item.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Image"])},
  "shop.item.isOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires Processing"])},
  "shop.item.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "shop.item.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
  "shop.item.preview-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Image"])},
  "shop.item.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Price"])},
  "shop.item.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Sound"])},
  "shop.item.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Quantity"])},
  "shop.item.stockUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited Stock"])},
  "shop.item.userPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Period"])},
  "shop.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "shop.no-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No description available"])},
  "shop.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't added any items yet."])},
  "shop.orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "shop.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "shop.points.cost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " Points"])},
  "shop.public.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The streamer hasn't added any items yet."])},
  "shop.remaining.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining"])},
  "shop.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "shop.sales.onlyPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Pending Sales"])},
  "shop.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Settings"])},
  "shop.stats.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items"])},
  "shop.stats.pointsSpent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points Spent"])},
  "shop.stats.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "shop.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
  "stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream Title"])},
  "subscription-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription has been successfully terminated."])},
  "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
  "test-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Alert"])},
  "thanks-for-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for supporting Kicklet! Your subscription means a lot to us and helps us continue delivering great content"])},
  "thanks-for-supporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for supporting Kicklet!"])},
  "title-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The title has been saved."])},
  "try-it-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out!"])},
  "ungrouped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungrouped"])},
  "unsaved-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsaved Changes"])},
  "unsaved-changes-content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have unsaved changes on <span class=\"color-primary\">", _interpolate(_named("title")), "</span>"])},
  "unverify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Verification"])},
  "unverify.dialog.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes remove!"])},
  "unverify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "unverify.dialog.warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you have removed your Kick account you need to verify yourself again"])},
  "unverify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To link another account you have to remove this account first."])},
  "unverify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account is linked to a Kick profile"])},
  "update-from-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update from Kicklet"])},
  "upgrade-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade now!"])},
  "upgrade-to-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to Premium:"])},
  "upgrade-to-premium-by-pressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to Premium by pressing the button and unlock more features and higher limits!"])},
  "usage.activeGiveaways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giveaways"])},
  "usage.assetsSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets storage"])},
  "usage.collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborator"])},
  "usage.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
  "usage.commandGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command groups"])},
  "usage.counterVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counter variables"])},
  "usage.moderationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderation rules"])},
  "usage.periodicMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodic messages"])},
  "usage.shopItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop Items"])},
  "usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])},
  "usage.variableStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable storage"])},
  "usage.widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "use-custom-file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use custom file name"])},
  "user-lookup.buttons.copy-playback-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy playback url"])},
  "user-lookup.buttons.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "user-lookup.buttons.visit-on-kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit on Kick"])},
  "user-lookup.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel created"])},
  "user-lookup.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover details such as followers, channel creation date, user ID, and even the playback URL if the streamer is currently live. Get information's with our User Lookup feature."])},
  "user-lookup.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follower"])},
  "user-lookup.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "user-lookup.is-banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is banned"])},
  "user-lookup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Lookup"])},
  "user-lookup.user-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "user-lookup.user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found"])},
  "user-lookup.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "user-switched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully changed!"])},
  "validation.float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be a decimal number"])},
  "validation.integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be an integer"])},
  "validation.length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Length must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max"))])},
  "validation.max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum for this value is ", _interpolate(_named("max"))])},
  "validation.min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimum for this value is ", _interpolate(_named("min"))])},
  "validation.no-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No spaces are allowed"])},
  "validation.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be an number"])},
  "validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
  "validation.size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Size must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max"))])},
  "verify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify now"])},
  "verify.dialog.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the command below."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open <a class='color-primary' href='https://kick.com/kicklet/chatroom' target='_blank'>https://kick.com/kicklet/chatroom</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type the copied command in the chatroom of Kicklet's channel."])}
  ],
  "verify.dialog.modList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First, you need to add Kicklet as a moderator to your Kick channel."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open <a class='color-primary' href='https://kick.com/dashboard/community/moderators' target='_blank'>https://kick.com/dashboard/community/moderators</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And add <span class='color-primary'>Kicklet</span> as a moderator on your channel."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternatively, you can also type /mod Kicklet in YOUR chat."])}
  ],
  "verify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
  "verify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To use this functionality you need to be verified with your Kick.com account!"])},
  "verify.no-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
  "verify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification required"])},
  "view-charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Charts"])},
  "visit-public-shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Public Shop"])},
  "vod-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOD Downloader"])},
  "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
  "widget-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Control"])},
  "widget.creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "widget.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])},
  "widget.my-widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Widgets"])},
  "widgets.asset-manager.choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "widgets.asset-manager.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "widgets.asset-manager.edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Mode"])},
  "widgets.asset-manager.image-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Asset Manager"])},
  "widgets.asset-manager.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
  "widgets.asset-manager.my-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Images"])},
  "widgets.asset-manager.my-sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Sounds"])},
  "widgets.asset-manager.play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "widgets.asset-manager.sound-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound Asset Manager"])},
  "widgets.asset-manager.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "widgets.asset-manager.upload-dialog.drag-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag Your Files Here"])},
  "widgets.asset-manager.upload-dialog.drag-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or <strong><em>click here</em></strong> to select files"])},
  "widgets.asset-manager.upload-dialog.drop-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop them here"])},
  "widgets.asset-manager.upload-dialog.drop-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to add them"])},
  "widgets.asset-manager.upload-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Image"])},
  "widgets.asset-manager.upload-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Sound"])},
  "widgets.buttons.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "widgets.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["the widget <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "widgets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "widgets.description.drag-obs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag me into your OBS"])},
  "widgets.description.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this guide assumes you already have OBS installed and are familiar with its basic functions. Let us know on our discord if you have any further questions!"])},
  "widgets.description.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open your OBS."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new Browser source."])},
    (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set the width to ", _interpolate(_named("width")), " and the height to ", _interpolate(_named("height")), "."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the copied URL into the URL field."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on okay."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now place your widget where you like it by dragging it around"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy your new widget!"])}
  ],
  "widgets.description.method1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method 1 (Super Easy)"])},
  "widgets.description.method2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method 2 (The Good Old Way)"])},
  "widgets.description.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to add the Widget to OBS"])},
  "widgets.new-widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Widget"])},
  "widgets.no-widgets-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven''t created any widget yet. Start now by visiting the store to create your first widget."])},
  "widgets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "widgets.widget-import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use Widget"])},
  "widgets.widget-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget Name"])},
  "with-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Discord"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOU"])},
  "your-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Plan:"])},
  "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
  "variables.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create variable"])},
  "variables.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["the Variable <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "variables.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "variables.dialog.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewer"])},
  "variables.no-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no variables. Starting with creating a new."])},
  "viewer-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewer variables"])},
  "discord-member-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["members"])},
  "form.widget.showSpider.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Spider"])},
  "form.widget.showGhost.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Ghost"])},
  "select-and-install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select & Install"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "link-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied to clipboard!"])},
  "widget.preview.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "widget.preview.advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This widget is"])},
  "no-data-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
  "items-per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items per page"])},
  "of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("itemRange")), " of ", _interpolate(_named("length"))])},
  "backend.errors.alias already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias is already used in another command."])},
  "bot.command-groups.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Group"])},
  "locales.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "locales.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "locales.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
  "api-token.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your API tokens here. For example, integrate them with the"])}
}