export default {
  "accept-tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto los Términos de Servicio"])},
  "add-to-stream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a stream"])},
  "admin.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "advanced-editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor Avanzado"])},
  "assets.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el Activo"])},
  "assets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "audit-logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit Logs"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "backend.errors.BOT_COMMAND_ALIAS_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> ya es un comando existente"])},
  "backend.errors.BOT_COMMAND_ALIAS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uno de tus alias está vacío"])},
  "backend.errors.BOT_COMMAND_ALIAS_SELF_DUPLICATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu alias <span class='color-primary'>", _interpolate(_named("alias")), "</span> es el mismo que tu comando"])},
  "backend.errors.BOT_COMMAND_MIN_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu comando requiere al menos un permiso"])},
  "backend.errors.already canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu suscripción ya ha sido cancelada y continuará solo por el período que ya has pagado."])},
  "backend.errors.command already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tienes otro comando con ese nombre dado"])},
  "backend.errors.config limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se excedió el límite de caracteres."])},
  "backend.errors.forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tienes permiso para realizar esta acción"])},
  "backend.errors.giveaway already ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El sorteo ya ha terminado"])},
  "backend.errors.invalid JSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocurrió un error."])},
  "backend.errors.limit exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has alcanzado el límite."])},
  "backend.errors.max storage size exceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo no puede ser subido ya que excedería el límite de almacenamiento."])},
  "backend.errors.moderation rule name already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe una regla con este nombre"])},
  "backend.errors.no entries to roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay entradas para sortear"])},
  "backend.errors.premium only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta función está disponible actualmente solo para usuarios Premium."])},
  "backend.errors.user dont activate collaboration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario no ha activado la colaboración"])},
  "backend.errors.validation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Validation failed! ", _interpolate(_named("messages"))])},
  "backend.errors.version already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta versión ya existe"])},
  "bot.alert-messages.FOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir"])},
  "bot.alert-messages.HOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfitrión"])},
  "bot.alert-messages.SUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
  "bot.alert-messages.SUB_GIFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones regaladas"])},
  "bot.alert-messages.UNFOLLOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar de seguir"])},
  "bot.alert-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar el mensaje"])},
  "bot.alert-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
  "bot.alert-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes de Alerta"])},
  "bot.command-group.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupo de Comandos - ", _interpolate(_named("name"))])},
  "bot.command-groups.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar el grupo de comandos"])},
  "bot.command-groups.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Grupo"])},
  "bot.command-groups.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["el Grupo de Comandos <span class='color-primary'>", _interpolate(_named("name")), "</span> y todos los comandos contenidos"])},
  "bot.command-groups.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de Comandos"])},
  "bot.command-groups.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Grupo"])},
  "bot.command-groups.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Grupos de Comandos (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.command-groups.labels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "bot.command-groups.labels.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "bot.command-groups.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes tener más grupos de comandos"])},
  "bot.command-groups.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede crear"])},
  "bot.command-groups.no-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no tienes grupos. Comienza creando uno nuevo."])},
  "bot.command-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de Comandos"])},
  "bot.command.errors.optional-after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ningún argumento posterior a eso debería ser obligatorio!"])},
  "bot.command.errors.required-before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Todos los argumentos anteriores deben ser obligatorios para eso!"])},
  "bot.command.labels.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "bot.command.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comando - !", _interpolate(_named("command"))])},
  "bot.commands.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar un comando"])},
  "bot.commands.aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alias"])},
  "bot.commands.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Comando"])},
  "bot.commands.custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado"])},
  "bot.commands.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["el Comando <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "bot.commands.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando"])},
  "bot.commands.detail-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comando - ", _interpolate(_named("name"))])},
  "bot.commands.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comandos (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.commands.import-from-marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar del Mercado"])},
  "bot.commands.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
  "bot.commands.no-aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin alias"])},
  "bot.commands.no-commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no tienes comandos en este grupo. Comienza creando uno nuevo."])},
  "bot.commands.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permisos"])},
  "bot.commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos"])},
  "bot.creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador"])},
  "bot.default-commands.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos Predeterminados"])},
  "bot.gallery.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])},
  "bot.global-messages.NOT_ENOUGH_POINTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos insuficientes"])},
  "bot.global-messages.NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin permiso"])},
  "bot.global-messages.PERIOD_NOT_REACHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfriamiento de comando"])},
  "bot.global-messages.USABILITY_NOT_MATCHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stream live estado no coincidente"])},
  "bot.global-messages.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "bot.global-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes globales"])},
  "bot.marketplace-creator.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador del Mercado"])},
  "bot.marketplace.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
  "bot.moderation-rule.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar la regla"])},
  "bot.moderation.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Regla"])},
  "bot.moderation.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderación"])},
  "bot.periodic-messages.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar / Desactivar el mensaje"])},
  "bot.periodic-messages.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear Mensaje"])},
  "bot.periodic-messages.delete.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este mensaje periódico"])},
  "bot.periodic-messages.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje Periódico"])},
  "bot.periodic-messages.edit-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Mensaje"])},
  "bot.periodic-messages.fmt-title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mensajes Periódicos (", _interpolate(_named("current")), "/", _interpolate(_named("max")), ")"])},
  "bot.periodic-messages.max-groups.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes tener más grupos de comandos"])},
  "bot.periodic-messages.max-groups.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede crear"])},
  "bot.periodic-messages.no-periodic-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no tienes mensajes periódicos. Comienza creando uno nuevo."])},
  "bot.periodic-messages.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes Periódicos"])},
  "bot.points.points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos"])},
  "bot.points.points-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Puntos"])},
  "bot.points.points-per-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos por mensaje"])},
  "bot.points.points-per-watchtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos por minuto de visualización"])},
  "bot.points.subs-multiplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplicador de Suscriptores"])},
  "bot.points.subscriber-bonus-points": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tus suscriptores recibirán puntos x", _interpolate(_named("multiplier")), "."])},
  "bot.points.subscriber-same-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus suscriptores recibirán la misma cantidad de puntos que cualquier otro espectador."])},
  "bot.points.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema de Puntos"])},
  "bot.song-requests.no-songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no se han añadido canciones. Puedes añadir algunas en tu chat o permitir que tus espectadores las añadan usando el comando !sr (URL de YT)"])},
  "bot.song-requests.now-playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REPRODUCIENDO AHORA:"])},
  "bot.song-requests.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Peticiones de Canciones (", _interpolate(_named("min")), "/", _interpolate(_named("max")), ")"])},
  "bot.song-requests.title-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peticiones de Canciones"])},
  "bot.songrequest-command.active-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AActivar / Desactivar el comando de Song request"])},
  "bot.stats.charts.chat-activities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades del Chat"])},
  "bot.stats.charts.command-executions-pie.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecuciones de Comandos"])},
  "bot.stats.charts.command-executions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecuciones de Comandos"])},
  "bot.stats.errors.too-much-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demasiados datos. Intenta seleccionar un rango menor o un intervalo mayor."])},
  "bot.stats.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin Datos"])},
  "bot.stats.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comienza tu primera transmisión en vivo para recopilar estadísticas sobre tus transmisiones."])},
  "bot.stats.range.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas 4 Semanas"])},
  "bot.stats.range.week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos 7 Días"])},
  "bot.stats.range.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último Año"])},
  "bot.stats.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
  "bot.stats.viewer.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar espectador"])},
  "bot.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Bot"])},
  "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
  "chat-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Downloader"])},
  "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
  "clear-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar todo"])},
  "click-an-emote-to-copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en un emoticono para copiarlo"])},
  "collaborators.dialog.add.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Colaborador"])},
  "collaborators.dialog.delete-managed-users.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "collaborators.dialog.delete-managed-users.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario Administrado"])},
  "collaborators.dialog.edit.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editar permisos de <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "collaborators.dialog.edit.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Colaborador"])},
  "collaborators.dialog.permissions.alert-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes de Alerta"])},
  "collaborators.dialog.permissions.audit-log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit-Log"])},
  "collaborators.dialog.permissions.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos"])},
  "collaborators.dialog.permissions.counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables de Contador"])},
  "collaborators.dialog.permissions.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
  "collaborators.dialog.permissions.danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚠️ Peligro"])},
  "collaborators.dialog.permissions.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "collaborators.dialog.permissions.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "collaborators.dialog.permissions.execute-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutar Código"])},
  "collaborators.dialog.permissions.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
  "collaborators.dialog.permissions.giveaway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo"])},
  "collaborators.dialog.permissions.global-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes Globales"])},
  "collaborators.dialog.permissions.kick-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad"])},
  "collaborators.dialog.permissions.moderation-chat-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de Moderación"])},
  "collaborators.dialog.permissions.overlay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overlays"])},
  "collaborators.dialog.permissions.periodic-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes Periódicos"])},
  "collaborators.dialog.permissions.shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])},
  "collaborators.dialog.permissions.song": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes de Canciones"])},
  "collaborators.dialog.permissions.stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
  "collaborators.dialog.permissions.stream-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título del Stream"])},
  "collaborators.dialog.permissions.test-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos de Prueba"])},
  "collaborators.dialog.permissions.toggle-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar"])},
  "collaborators.dialog.permissions.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "collaborators.dialog.permissions.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
  "collaborators.dialog.permissions.view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
  "collaborators.dialog.remove.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "collaborators.dialog.remove.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "collaborators.dialog.remove.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar a <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> de tus colaboradores?"])},
  "collaborators.dialog.remove.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Colaborador"])},
  "collaborators.managed.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar Kicklet como"])},
  "collaborators.menutitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaboración"])},
  "collaborators.table.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "collaborators.table.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado en"])},
  "collaborators.table.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "collaborators.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colaboradores"])},
  "collaborators.toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros usuarios pueden agregarme como colaborador"])},
  "command-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando"])},
  "config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
  "counter-variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable Contadora"])},
  "counter-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables Contadoras"])},
  "coupon-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cupón (opcional)"])},
  "dashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "dialog.abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abortar"])},
  "dialog.are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro?"])},
  "dialog.buy.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar Ahora"])},
  "dialog.buy.inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca la información requerida"])},
  "dialog.buy.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar Artículo"])},
  "dialog.cancel-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar terminará las funciones premium después de este período de facturación. Para preguntas o ayuda, contáctanos en Discord. Confirma abajo para proceder. ¡Gracias!"])},
  "dialog.command-config.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar Comando"])},
  "dialog.command-move.select-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de Comandos"])},
  "dialog.command-move.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover Comando"])},
  "dialog.confirm.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "dialog.confirm.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres continuar? Esta acción no se puede deshacer."])},
  "dialog.confirm.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar acción"])},
  "dialog.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
  "dialog.delete.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "dialog.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar ", _interpolate(_named("item")), " de forma permanente?"])},
  "dialog.delete.title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminar ", _interpolate(_named("item"))])},
  "dialog.giveaway.chat-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje de chat"])},
  "dialog.giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costos"])},
  "dialog.giveaway.entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada"])},
  "dialog.giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de inactividad"])},
  "dialog.giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra clave"])},
  "dialog.giveaway.output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salida"])},
  "dialog.giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo"])},
  "dialog.logout.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "dialog.logout.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres cerrar sesión?"])},
  "dialog.logout.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "dialog.moderation-rule.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
  "dialog.moderation-rule.action-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "dialog.moderation-rule.ignore-case": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar mayúsculas y minúsculas"])},
  "dialog.moderation-rule.ignore-emote-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar solo emote"])},
  "dialog.moderation-rule.min-characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo de caracteres"])},
  "dialog.moderation-rule.min-messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo de mensajes"])},
  "dialog.moderation-rule.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "dialog.moderation-rule.similar-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de similitud"])},
  "dialog.moderation-rule.similar-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje"])},
  "dialog.moderation-rule.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de regla"])},
  "dialog.moderation-rule.trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])},
  "dialog.moderation-rule.trigger-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "dialog.moderation-rule.within-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marco de tiempo"])},
  "dialog.ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "dialog.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "dialog.shop.custom-fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos Personalizados"])},
  "dialog.shop.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del Artículo"])},
  "dialog.shop.item-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción del Artículo"])},
  "dialog.shop.item-config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración del Artículo"])},
  "discard-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descartar cambios"])},
  "discord.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
  "docs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "downloader.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "downloader.end": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fin: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración:"])},
  "downloader.messages-downloaded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mensajes descargados: ", _interpolate(_named("messagesDownloaded"))])},
  "downloader.quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad"])},
  "downloader.start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inicio: ", _interpolate(_named("convertSegmentToTime"))])},
  "downloader.stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detener"])},
  "downloader.time-elapsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tiempo transcurrido: ", _interpolate(_named("formatVideoTime"))])},
  "downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título:"])},
  "downloader.url-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserte una URL válida de video de Kick"])},
  "downloader.video-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del Video:"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["borrador"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "editing-as-username": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Editando como ", _interpolate(_named("username"))])},
  "emote-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El emoticono ha sido copiado a tu portapapeles."])},
  "emotes.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "emotes.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
  "emotes.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente"])},
  "emotes.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "emotes.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombra"])},
  "emotes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generador de Emotes"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "event.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "event.gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscripción regalada"])},
  "event.host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "event.sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
  "event.unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
  "extension-advantages": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat Claro: Desactiva mensajes de Kicklet para mayor legibilidad."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de Comandos: Muestra todos los comandos con \"!\"."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interacción Mejorada: Aumenta la participación de los espectadores."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil de Usar: Simple para los espectadores."])}
  ],
  "extension.features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventajas"])},
  "extension.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extensión"])},
  "file.invalid-type": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¡El archivo <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span> no es un tipo de archivo válido!"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de archivo"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
  "forever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para siempre"])},
  "form.addcollab.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "form.addcollab.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
  "form.command-group.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "form.command-group.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "form.command.aliases.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.command.aliases.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.command.aliases.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias"])},
  "form.command.arguments.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumentos"])},
  "form.command.arguments.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumentos Nombrados"])},
  "form.command.arguments.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "form.command.arguments.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requerido"])},
  "form.command.asResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como Respuesta"])},
  "form.command.availability.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
  "form.command.category.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "form.command.changelog.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de cambios"])},
  "form.command.command.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando"])},
  "form.command.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando"])},
  "form.command.config.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "form.command.config.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "form.command.costs.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo"])},
  "form.command.description.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "form.command.examples.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplos"])},
  "form.command.examples.single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo"])},
  "form.command.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período Global"])},
  "form.command.image.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "form.command.messageResponse.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta del Comando"])},
  "form.command.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "form.command.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
  "form.command.template.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla"])},
  "form.command.usage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilidad"])},
  "form.command.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de Usuario"])},
  "form.command.version.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión"])},
  "form.counter-variable.counter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contador"])},
  "form.counter-variable.name.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "form.overlay-alert-part.gif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gif"])},
  "form.overlay-alert-part.sound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "form.overlay-alert-part.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.overlay-alert-part.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo en segundos"])},
  "form.overlay-alert-part.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.overlay-alert.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de Fuente"])},
  "form.overlay-alert.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Primario"])},
  "form.overlay-alert.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Secundario"])},
  "form.overlay-alert.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombra de Texto"])},
  "form.overlay-alert.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto sobre GIF"])},
  "form.periodic-message.message.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "form.periodic-message.messagesThreshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes Requeridos"])},
  "form.periodic-message.period.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
  "form.periodic-message.pinMessage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijar mensaje"])},
  "form.shop.settings.command.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta del Comando"])},
  "form.shop.settings.globalPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período Global"])},
  "form.shop.settings.userPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período del Usuario"])},
  "form.widget.animated.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animado"])},
  "form.widget.animation.fab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotar"])},
  "form.widget.animation.fade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aparecer"])},
  "form.widget.animation.floating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flotante"])},
  "form.widget.animation.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animación"])},
  "form.widget.animation.scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrandar"])},
  "form.widget.animation.slide-x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deslizar desde la izquierda"])},
  "form.widget.animation.slide-x-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deslizar desde la derecha"])},
  "form.widget.animation.slide-y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deslizar desde arriba"])},
  "form.widget.animation.slide-y-reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deslizar desde abajo"])},
  "form.widget.animation.snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieve"])},
  "form.widget.animationDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la animación"])},
  "form.widget.appearance.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apariencia"])},
  "form.widget.backgroundColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color de fondo"])},
  "form.widget.badges.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar insignias"])},
  "form.widget.bars.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barras"])},
  "form.widget.barsRatio.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relación de Barras"])},
  "form.widget.bold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negrita"])},
  "form.widget.border.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borde"])},
  "form.widget.borderColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color del borde"])},
  "form.widget.bots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar mensajes de bots"])},
  "form.widget.color.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "form.widget.cooldownPeriod.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de enfriamiento (segundos)"])},
  "form.widget.defaultCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Commands"])},
  "form.widget.displayDelay.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraso de visualización"])},
  "form.widget.displayDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la visualización"])},
  "form.widget.duration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración"])},
  "form.widget.emoteSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de emote"])},
  "form.widget.flameIcon.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuego"])},
  "form.widget.follow.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
  "form.widget.followActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "form.widget.followDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración (segundos)"])},
  "form.widget.followFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de pantalla completa"])},
  "form.widget.followGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
  "form.widget.followPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición absoluta"])},
  "form.widget.followPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen izquierdo"])},
  "form.widget.followPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen superior"])},
  "form.widget.followSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "form.widget.followText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.followVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.widget.font.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente"])},
  "form.widget.fontSize.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de la fuente"])},
  "form.widget.goal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
  "form.widget.horizontal.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontal"])},
  "form.widget.host.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host"])},
  "form.widget.hostActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "form.widget.hostDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración (segundos)"])},
  "form.widget.hostFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de pantalla completa"])},
  "form.widget.hostGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
  "form.widget.hostPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición absoluta"])},
  "form.widget.hostPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen izquierdo"])},
  "form.widget.hostPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen superior"])},
  "form.widget.hostSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "form.widget.hostText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.hostVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.widget.hypeTrainOverText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.ignoreBots.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar Bots"])},
  "form.widget.ignoreCommands.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar Comandos"])},
  "form.widget.ignoreEmojis.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar Emojis"])},
  "form.widget.items.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icono"])},
  "form.widget.items.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.items.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
  "form.widget.levelCompletedText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.levelGoals.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de dificultad de los objetivos"])},
  "form.widget.limit.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
  "form.widget.loadOldMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar mensajes antiguos"])},
  "form.widget.max.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
  "form.widget.minStreak.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Racha mínima"])},
  "form.widget.multiplier.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplicador"])},
  "form.widget.noSongSubTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin subtítulo de canción"])},
  "form.widget.noSongTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin título de canción"])},
  "form.widget.participateText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.pauseDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la Pausa"])},
  "form.widget.permissions.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permiso"])},
  "form.widget.pill.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Píldora"])},
  "form.widget.primaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color primario"])},
  "form.widget.readUsername.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leer Nombre de Usuario"])},
  "form.widget.removeMessages.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar mensajes antiguos"])},
  "form.widget.removeMessagesAfter.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraso (segundos)"])},
  "form.widget.roles.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
  "form.widget.rounded.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redondeado"])},
  "form.widget.secondaryColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color secundario"])},
  "form.widget.sevenTV.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar emotes de SevenTV"])},
  "form.widget.shadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombra"])},
  "form.widget.showImage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar imagen"])},
  "form.widget.showNumbers.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar números"])},
  "form.widget.showPercentage.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar porcentaje"])},
  "form.widget.showProfilePicture.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar foto de perfil"])},
  "form.widget.showProgress.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar progreso"])},
  "form.widget.showTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la visualización"])},
  "form.widget.showVideo.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar video"])},
  "form.widget.showVotes.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar votos"])},
  "form.widget.split.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividir"])},
  "form.widget.striped.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rayas"])},
  "form.widget.sub.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscriptor"])},
  "form.widget.subActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "form.widget.subDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración (segundos)"])},
  "form.widget.subFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de pantalla completa"])},
  "form.widget.subGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
  "form.widget.subGift.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regalo de Suscripción"])},
  "form.widget.subGiftActive.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
  "form.widget.subGiftDuration.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración (segundos)"])},
  "form.widget.subGiftFullSizeImg.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de pantalla completa"])},
  "form.widget.subGiftGif.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
  "form.widget.subGiftPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición absoluta"])},
  "form.widget.subGiftPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen izquierdo"])},
  "form.widget.subGiftPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen superior"])},
  "form.widget.subGiftSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "form.widget.subGiftText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.subGiftVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.widget.subPositionAbsolute.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición absoluta"])},
  "form.widget.subPositionLeft.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen izquierdo"])},
  "form.widget.subPositionTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen superior"])},
  "form.widget.subSound.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "form.widget.subText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.subTitle.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtítulo"])},
  "form.widget.subVolume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.widget.subscriptionsText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.supportText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.switchTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de cambio (segundos)"])},
  "form.widget.text.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "form.widget.textColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color del texto"])},
  "form.widget.textShadow.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombra del texto"])},
  "form.widget.textTop.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto Superior"])},
  "form.widget.threshold.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral"])},
  "form.widget.ticksColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color de las marcas"])},
  "form.widget.time.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
  "form.widget.title.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
  "form.widget.titleText.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "form.widget.trackTime.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir el tiempo"])},
  "form.widget.useIdentityColor.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar color de identidad"])},
  "form.widget.voice.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voz"])},
  "form.widget.volume.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "form.widget.windowName.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la ventana"])},
  "form.widget.words.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabras"])},
  "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis"])},
  "gifted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regalado"])},
  "giveaway.chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "giveaway.costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costos:"])},
  "giveaway.create-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear sorteo"])},
  "giveaway.details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "giveaway.end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar"])},
  "giveaway.entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participaciones"])},
  "giveaway.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "giveaway.export.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
  "giveaway.export.timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluir marca de tiempo"])},
  "giveaway.inactivity-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera por inactividad:"])},
  "giveaway.keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra clave:"])},
  "giveaway.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje:"])},
  "giveaway.no-timeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los usuarios no son descalificados del sorteo por inactividad."])},
  "giveaway.reroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo sorteo"])},
  "giveaway.roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo"])},
  "giveaway.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteo"])},
  "giveaway.winners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganadores"])},
  "global-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración Global"])},
  "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
  "go-premium-now-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hazte Premium ahora"])},
  "graphic-design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño Gráfico"])},
  "home.charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza la actividad del chat de tu audiencia con Kicklet"])},
  "home.charts-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos visuales para un análisis profundo de comandos y mensajes"])},
  "home.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería Infinita de Recursos para Streaming"])},
  "home.gallery-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximiza tu transmisión con nuestra diversa colección de widgets y comandos preprogramados, incluyendo todo desde minijuegos hasta características interactivas únicas."])},
  "home.header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los streamers están cambiando a Kicklet."])},
  "home.js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa comandos personalizados usando JavaScript"])},
  "home.js-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eleva tu experiencia de chat con soluciones de script personalizadas"])},
  "home.kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿El mejor chatbot para Kick?"])},
  "home.kicklet-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kicklet es reconocido como el mejor chatbot para Kick, ideal para streamers de todos los niveles. Con nuestras características innovadoras y widgets de superposición personalizables, mejoramos cada transmisión y nos destacamos como favorito de la comunidad."])},
  "home.offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo que ofrecemos:"])},
  "home.sub-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Descubre por qué!"])},
  "home.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
  "home.trusted-streamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increíbles streamers que confían en nosotros"])},
  "home.what-is-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es Kicklet?"])},
  "home.widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets de superposición únicos"])},
  "home.widgets-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros widgets son únicos y altamente personalizables, lo que te permite darle a tu transmisión una identidad distintiva propia."])},
  "home.youtube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Aprende más sobre Kicklet en nuestro video de YouTube!"])},
  "home.youtube-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mira nuestro video corto para tener una idea de Kicklet, nuestro chatbot."])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POPULAR"])},
  "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar"])},
  "join-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Únete a nuestro Discord!"])},
  "kick-video-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick Video URL"])},
  "kick.identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad"])},
  "latest.events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos Eventos"])},
  "latest.livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última transmisión en vivo"])},
  "login-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión requerido"])},
  "login-required-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a esta página, se requiere iniciar sesión. Puede iniciar sesión fácilmente utilizando Discord y comenzar."])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])},
  "moderation-rule.action.BAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban"])},
  "moderation-rule.action.DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "moderation-rule.action.TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
  "moderation-rule.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["la regla <span class='color-primary font-weight-bold'>", _interpolate(_named("name")), "</span>"])},
  "moderation-rule.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regula"])},
  "moderation-rule.no-longer-exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta regla de moderación ya no existe."])},
  "moderation-rule.trigger.CONTAINS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contiene"])},
  "moderation-rule.trigger.ENDS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termina con"])},
  "moderation-rule.trigger.EQUALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual"])},
  "moderation-rule.trigger.REGEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expresión Regular"])},
  "moderation-rule.trigger.SPAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spam"])},
  "moderation-rule.trigger.STARTS_WITH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empieza con"])},
  "moderation-rule.trigger.UPPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayúsculas"])},
  "moderation-rule.trigger.URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear enlaces"])},
  "moderation-rule.trigger.URL.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por defecto, todos los enlaces están bloqueados. Puedes agregar excepciones a continuación en el formato \"kick.com\""])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensual"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])},
  "multi-stream.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mira a varios streamers juntos con un enlace generado. Perfecto para co-transmitir o juegos en grupo. Disfruta de una experiencia de multi-transmisión sin interrupciones."])},
  "multi-stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Stream"])},
  "navigation.commandsAndTimers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos y Timers"])},
  "navigation.dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de Datos"])},
  "navigation.graphic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño Gráfico"])},
  "navigation.help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
  "navigation.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
  "navigation.marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
  "navigation.userInteraction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interacción del Usuario"])},
  "navigation.utils": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilidades"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUEVO"])},
  "no-collab-permission-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El streamer no le ha dado los permisos necesarios para ver esto."])},
  "no-latest-livestream": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has transmitido desde que Kicklet está activo. Después de tu primera transmisión, verás aquí las estadísticas de tu transmisión en vivo."])},
  "no-more-widgets-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay más widgets disponibles"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
  "origin-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["datos origen"])},
  "origin-data-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar la casilla para restablecer los Datos de Origen Registrados por Kicklet; dejar sin marcar para restablecer a cero."])},
  "page-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada."])},
  "panels.buttons.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "panels.buttons.generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
  "panels.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A continuación, puedes personalizar todas las configuraciones para tus paneles y, con solo hacer clic en el botón 'Generar', descargar fácilmente tu panel. Crea todos los paneles que necesites en cuestión de segundos y completamente gratis. Si tienes alguna sugerencia para diferentes formas, fuentes o cualquier otra cosa, no dudes en hacer una propuesta en nuestro servidor de Discord."])},
  "panels.labels.font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente"])},
  "panels.labels.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icono"])},
  "panels.labels.panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
  "panels.labels.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto"])},
  "panels.labels.text-shadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sombra"])},
  "panels.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generador de Paneles"])},
  "periodic-messages-editor.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje Periódico"])},
  "point-settings-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los ajustes de puntos han sido guardados"])},
  "premium.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa:"])},
  "private-profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "profile.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando"])},
  "profile.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos"])},
  "profile.permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
  "profile.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "profile.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación"])},
  "profile.song-queue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canciones"])},
  "profile.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "profile.verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "public.button.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Perfil Público"])},
  "public.profile.toggle.commands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar página de comandos"])},
  "public.profile.toggle.ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar página de clasificación"])},
  "public.profile.toggle.songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar página de cola de canciones"])},
  "public.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuraciones del perfil público"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprado"])},
  "ranking.no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin Datos"])},
  "ranking.no-data-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos que se puedan mostrar sobre los espectadores de este streamer"])},
  "ranking.no-streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer no encontrado"])},
  "ranking.subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Espectadores"])},
  "ranking.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏆 Ranking 🏆"])},
  "recent-livestreams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas transmisiones en vivo"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetear"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "save-20-anually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorre 20% anualmente"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
  "saved-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardado exitosamente"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "search-username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar nombre de usuario.."])},
  "search.no-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu búsqueda no ha dado ningún resultado."])},
  "see-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "settings.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
  "shop.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda Activa"])},
  "shop.copy-and-use-command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar y Usar Comando"])},
  "shop.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "shop.export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "shop.export.summarize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumir las mismas ventas por cliente"])},
  "shop.export.preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa"])},
  "shop.item.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
  "shop.item.action.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
  "shop.item.action.custom-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento Personalizado"])},
  "shop.item.action.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen"])},
  "shop.item.action.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "shop.item.action.message-conflict-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay un conflicto con esta acción."])},
  "shop.item.action.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
  "shop.item.action.unique-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción solo se puede realizar una vez."])},
  "shop.item.actionDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la Acción"])},
  "shop.item.actionVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen de la Acción"])},
  "shop.item.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "shop.item.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comando del Artículo"])},
  "shop.item.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del Artículo"])},
  "shop.item.event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento del Artículo"])},
  "shop.item.globalPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período Global"])},
  "shop.item.image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen del Artículo"])},
  "shop.item.isOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requiere Procesamiento"])},
  "shop.item.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
  "shop.item.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del Artículo"])},
  "shop.item.preview-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imagen de Vista Previa"])},
  "shop.item.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio del Artículo"])},
  "shop.item.sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido del Artículo"])},
  "shop.item.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de Stock"])},
  "shop.item.stockUnlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Ilimitado"])},
  "shop.item.userPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período del Usuario"])},
  "shop.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
  "shop.no-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay descripción disponible"])},
  "shop.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no has agregado ningún artículo."])},
  "shop.orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Órdenes"])},
  "shop.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
  "shop.points.cost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("price")), " Puntos"])},
  "shop.public.no-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El streamer aún no ha agregado ningún artículo."])},
  "shop.remaining.stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restante"])},
  "shop.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
  "shop.sales.onlyPending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo Ventas Pendientes"])},
  "shop.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la Tienda"])},
  "shop.stats.items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
  "shop.stats.pointsSpent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos Gastados"])},
  "shop.stats.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
  "shop.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saltar"])},
  "stream.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título del Stream"])},
  "subscription-cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu suscripción ha sido cancelada con éxito."])},
  "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de Servicio"])},
  "test-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probar Alerta"])},
  "thanks-for-sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por apoyar a Kicklet! Tu suscripción significa mucho para nosotros y nos ayuda a seguir entregando contenido excelente."])},
  "thanks-for-supporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por apoyar a Kicklet!"])},
  "title-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El título ha sido guardado."])},
  "try-it-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Pruébalo!"])},
  "ungrouped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin agrupar"])},
  "unsaved-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios no guardados"])},
  "unsaved-changes-content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tienes cambios no guardados en <span class=\"color-primary\">", _interpolate(_named("title")), "</span>"])},
  "unverify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar Verificación"])},
  "unverify.dialog.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Sí, eliminar!"])},
  "unverify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro?"])},
  "unverify.dialog.warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de haber eliminado tu cuenta de Kick necesitarás verificar tu identidad de nuevo"])},
  "unverify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para vincular otra cuenta primero tienes que eliminar esta cuenta."])},
  "unverify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta está vinculada a un perfil de Kick"])},
  "update-from-kicklet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización por Kicklet"])},
  "upgrade-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Actualiza ahora!"])},
  "upgrade-to-premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar a Premium:"])},
  "upgrade-to-premium-by-pressing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Mejora a Premium pulsando el botón y desbloquea más funciones y límites más altos!"])},
  "usage.activeGiveaways": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteos activos"])},
  "usage.assetsSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento de activos"])},
  "usage.collaborators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleados"])},
  "usage.command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comandos"])},
  "usage.commandGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de comandos"])},
  "usage.counterVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables de contador"])},
  "usage.moderationRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas de moderación"])},
  "usage.periodicMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes periódicos"])},
  "usage.shopItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos de la tienda"])},
  "usage.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])},
  "usage.variableStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento de variables"])},
  "usage.widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "use-custom-file-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar nombre de archivo personalizado"])},
  "user-lookup.buttons.copy-playback-url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar URL de reproducción"])},
  "user-lookup.buttons.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "user-lookup.buttons.visit-on-kick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitar en Kick"])},
  "user-lookup.created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal creado"])},
  "user-lookup.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre detalles como seguidores, fecha de creación del canal, ID de usuario e incluso la URL de reproducción si el streamer está en vivo actualmente. Obtén información con nuestra función de Búsqueda de Usuario."])},
  "user-lookup.follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguidor"])},
  "user-lookup.id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "user-lookup.is-banned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está baneado"])},
  "user-lookup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda de Usuario"])},
  "user-lookup.user-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Usuario"])},
  "user-lookup.user-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado"])},
  "user-lookup.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
  "user-switched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario cambiado con éxito!"])},
  "validation.float": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser un número decimal"])},
  "validation.integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser un número entero"])},
  "validation.length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La longitud debe estar entre ", _interpolate(_named("min")), " y ", _interpolate(_named("max"))])},
  "validation.max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El máximo para este valor es ", _interpolate(_named("max"))])},
  "validation.min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El mínimo para este valor es ", _interpolate(_named("min"))])},
  "validation.no-space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permiten espacios"])},
  "validation.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser un número"])},
  "validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo es obligatorio"])},
  "validation.size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El tamaño debe estar entre ", _interpolate(_named("min")), " y ", _interpolate(_named("max"))])},
  "verify.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar ahora"])},
  "verify.dialog.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia el comando a continuación."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abre <a class='color-primary' href='https://kick.com/kicklet/chatroom' target='_blank'>https://kick.com/kicklet/chatroom</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe el comando copiado en la sala de chat del canal de Kicklet."])}
  ],
  "verify.dialog.modList": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero, necesitas añadir a Kicklet como moderador en tu canal de Kick."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abre <a class='color-primary' href='https://kick.com/dashboard/community/moderators' target='_blank'>https://kick.com/dashboard/community/moderators</a>"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y añade a <span class='color-primary'>Kicklet</span> como moderador en tu canal."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternativamente, también puedes escribir <span class='color-primary'>/mod Kicklet</span> en TU chat."])}
  ],
  "verify.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación"])},
  "verify.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Para usar esta funcionalidad necesitas estar verificado con tu cuenta de Kick.com!"])},
  "verify.no-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No verificado"])},
  "verify.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere verificación"])},
  "view-charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver gráficos"])},
  "visit-public-shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitar Tienda Pública"])},
  "vod-downloader.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOD Downloader"])},
  "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
  "widget-control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control del widget"])},
  "widget.creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "widget.gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])},
  "widget.my-widgets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis Widgets"])},
  "widgets.asset-manager.choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elegir"])},
  "widgets.asset-manager.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "widgets.asset-manager.edit-mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de Edición"])},
  "widgets.asset-manager.image-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Asset Manager"])},
  "widgets.asset-manager.library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
  "widgets.asset-manager.my-images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis Imágenes"])},
  "widgets.asset-manager.my-sounds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis Sonidos"])},
  "widgets.asset-manager.play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproducir"])},
  "widgets.asset-manager.sound-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound Asset Manager"])},
  "widgets.asset-manager.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])},
  "widgets.asset-manager.upload-dialog.drag-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastra tus archivos aquí"])},
  "widgets.asset-manager.upload-dialog.drag-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o <strong><em>haz clic aquí</em></strong> para seleccionar archivos"])},
  "widgets.asset-manager.upload-dialog.drop-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soltar aquí"])},
  "widgets.asset-manager.upload-dialog.drop-files-small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para agregarlos"])},
  "widgets.asset-manager.upload-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir Imagen"])},
  "widgets.asset-manager.upload-sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir Sonido"])},
  "widgets.buttons.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
  "widgets.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["el widget <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "widgets.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
  "widgets.description.drag-obs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrástrame a tu OBS"])},
  "widgets.description.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten en cuenta que esta guía asume que ya tienes instalado OBS y estás familiarizado con sus funciones básicas. ¡Haznos saber en nuestro discord si tienes más preguntas!"])},
  "widgets.description.list": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abre tu OBS."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade una nueva fuente de navegador."])},
    (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configura el ancho a ", _interpolate(_named("width")), " y la altura a ", _interpolate(_named("height")), "."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pega la URL copiada en el campo de URL."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic en aceptar."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora coloca tu widget donde quieras arrastrándolo."])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Disfruta de tu nuevo widget!"])}
  ],
  "widgets.description.method1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método 1 (Super Fácil)"])},
  "widgets.description.method2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método 2 (El Buen Viejo Método)"])},
  "widgets.description.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo agregar el Widget a OBS"])},
  "widgets.new-widget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo Widget"])},
  "widgets.no-widgets-saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has creado ningún widget todavía. Comienza ahora visitando la tienda para crear tu primer widget."])},
  "widgets.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widgets"])},
  "widgets.widget-import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar Widget"])},
  "widgets.widget-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del Widget"])},
  "with-discord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con Discord"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["año"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
  "you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÚ"])},
  "your-plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu plan:"])},
  "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
  "variables.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear variable"])},
  "variables.delete.text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["la Variable <span class='color-primary'>", _interpolate(_named("name")), "</span>"])},
  "variables.delete.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.variable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable"])},
  "variables.dialog.value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
  "variables.dialog.viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visor"])},
  "variables.no-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no tienes variables. Comienza creando una nueva."])},
  "viewer-variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables del visor"])},
  "discord-member-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miembros"])},
  "form.widget.showSpider.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar araña"])},
  "form.widget.showGhost.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar fantasma"])},
  "select-and-install": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar e Instalar"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
  "link-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Enlace copiado al portapapeles!"])},
  "widget.preview.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "widget.preview.advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este widget es"])},
  "no-data-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos disponibles"])},
  "items-per-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos por página"])},
  "of": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("itemRange")), " de ", _interpolate(_named("length"))])},
  "backend.errors.alias already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El alias ya se usa en otro comando."])},
  "bot.command-groups.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar grupo"])},
  "locales.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "locales.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemán"])},
  "locales.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "api-token.manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre sus tokens de API aquí. Por ejemplo, intégralos con el"])}
}